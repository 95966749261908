import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  position: relative;
  & image {
  }
`

interface WavyProps {
  image: any
}

const Test: React.FC<WavyProps> = ({ image }) => {
  if (!image.localFile || !image.localFile.publicURL) return null

  return (
  <Svg
    width="1440"
    height="874"
    viewBox="0 0 1440 874"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      style={{ mixBlendMode: 'multiply' }}
      opacity="0.3"
      filter="url(#filter0_d_156_2)"
    >
      <path
        d="M828.078 665.948C464.603 745.723 73.1534 711.363 -135.671 694.491L-72.094 6.79087L1644 37.5L1625.8 361.376C1501.03 443.092 1216.65 580.664 828.078 665.948Z"
        fill="#F3F3F3"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} filter="url(#filter1_d_156_2)">
      <path
        d="M805.5 523C507.575 621.814 176.167 627.333 -0.5 631.5L-7.5 49L1448 53.5V197.5C1350.5 277 1124 417.362 805.5 523Z"
        fill="#F3F3F3"
      />
    </g>
    <g
      style={{ mixBlendMode: 'multiply' }}
      opacity="0.5"
      filter="url(#filter2_d_156_2)"
    >
      <path
        d="M832.038 587.596C518.74 679.629 173.982 673.462 -9.84559 671.447L3.80222 65.579L1511.73 74.5L1511.73 272.259C1407.5 351.412 1166.97 489.207 832.038 587.596Z"
        fill="#F3F3F3"
      />
    </g>
    <mask
      id="mask0_156_2"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="-2"
      y="-1"
      width="1442"
      height="604"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.5 603C180 603 597 555.348 864 456C1079.5 375.814 1362.5 207 1440 112V0H5.90222L-0.499998 -0.5L-1.5 603Z"
        fill="#263280"
      />
    </mask>
    <g mask="url(#mask0_156_2)">
      <rect
        width="1441"
        height="679"
        transform="matrix(-1 0 0 1 1441 0)"
        fill="url(#patternwavy)"
      />
      <rect x="-5" width="1447" height="700" fill="url(#patterntest)" />
    </g>
    <defs>
      <filter
        id="filter0_d_156_2"
        x="-165.671"
        y="-23.2091"
        width="1839.67"
        height="769.704"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_156_2"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_156_2"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_156_2"
        x="-37.5"
        y="19"
        width="1515.5"
        height="642.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_156_2"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_156_2"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_156_2"
        x="-39.8457"
        y="35.579"
        width="1581.58"
        height="666.765"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_156_2"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_156_2"
          result="shape"
        />
      </filter>
      <pattern
        id="patternwavy"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_156_2"
          transform="translate(1.00711 1.08241) scale(0.000246384 0.000522885) rotate(180)"
        />
      </pattern>
      <pattern
        id="patterntest"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image1_156_2"
          transform="translate(-0.040774 -0.419629) scale(0.000254095 0.000581772)"
        />
      </pattern>
      <image
        id="image1_156_2"
        width="4096"
        height="2731"
        xlinkHref={image.localFile.publicURL}
      />
    </defs>
  </Svg>
)}

export default Test
