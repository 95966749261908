import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Wavy from 'components/elements/Vectors/Wavy'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section`
  @media (min-width: 992px) {
    height: 80vh;
    min-height: 1000px;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: ${({ theme }) => theme.color.light};
    }

    & * {
      color: ${({ theme }) => theme.color.light};
    }
  }

  padding-top: 11rem;
`

const SVGWrapper = styled.div`
  pointer-events: none;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  max-width: 1440px;

  & svg {
    height: 100% !important;
    mask path {
      min-height: 500px;
    }
  }

  @media (max-width: 1399px) {
    position: relative;
    & svg {
      width: 100%;
      height: auto !important;
    }
  }

  @media (max-width: 991px) {
  }
`

const ContentWrapper = styled.div`
  top: 0;
`

const BannerWavy: React.FC<BannerProps> = ({ fields }) => (
  <Section className="position-relative">
    <SVGWrapper className="mx-auto">
      <Wavy image={fields.image} />
    </SVGWrapper>
    <ContentWrapper className="position-absolute w-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <Content content={fields.description} />
          </div>
        </div>
      </div>
    </ContentWrapper>
  </Section>
)

export default BannerWavy
